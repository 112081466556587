import React, { useRef, useEffect, useState } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Suspense } from "react";
import { OrbitControls } from "@react-three/drei";
import { AnimationMixer, Box3, Vector3 } from "three";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

import LoderModel from "./LoderModel";
import "./style.css";

const ModelView = () => {
  const [selectedAnimationClip, setSelectedAnimationClip] = useState(null);
  const [animationClips, setAnimationClips] = useState([]);

  function Model(props) {
    const group = useRef();
    const mixerRef = useRef();
    
    // Use FBXLoader to load the .fbx file
    const model = useLoader(FBXLoader,"https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/model/RENEW+(4).fbx");

    // Initialize animation mixer once the model is loaded
    useEffect(() => {
      if (model.animations && model.animations.length > 0) {
        setAnimationClips(model.animations);
        mixerRef.current = new AnimationMixer(model);
      }

      return () => {
        if (mixerRef.current) {
          mixerRef.current.stopAllAction();
          mixerRef.current.uncacheRoot(model);
        }
      };
    }, [model]);

    // Handle animation playing
    useEffect(() => {
      if (selectedAnimationClip && mixerRef.current) {
        const action = mixerRef.current.clipAction(selectedAnimationClip);
        action.reset().play();
        return () => {
          action.stop();
        };
      }
    }, [selectedAnimationClip]);

    // Center and scale the model to fit into the scene
    useEffect(() => {
      if (group.current) {
        const box = new Box3().setFromObject(group.current);
        const center = new Vector3();
        box.getCenter(center);
        group.current.position.sub(center);
        group.current.position.set(0, 0, 0);

        const size = new Vector3();
        box.getSize(size);
        const maxDimension = Math.max(size.x, size.y, size.z);
        const desiredSize = 4;
        const scale = desiredSize / maxDimension;
        group.current.scale.set(scale, scale, scale);
      }
    }, [model]);

    // Update the animation mixer on each frame
    useFrame((state, delta) => {
      if (mixerRef.current) {
        mixerRef.current.update(delta);
      }
    });

    return (
      <group ref={group}>
        <primitive {...props} object={model} />
      </group>
    );
  }

  return (
    <div className="h-lvh w-full">
      <Canvas
        className="_model"
        shadows
        dpr={[1, 2]}
        camera={{ position: [4, 3, 6], fov: 50 }}
      >
        <color attach="background" args={["rgb(28, 28, 32)"]} />
        <ambientLight intensity={0.3} />
        <directionalLight
          position={[10, 15, 10]}
          intensity={1.5}
          castShadow
          shadow-mapSize={{ width: 4096, height: 4096 }}
        />
        <directionalLight position={[-10, -10, -10]} intensity={0.5} />
        <spotLight
          position={[15, 25, 5]}
          angle={0.3}
          penumbra={0.7}
          intensity={2.5}
          castShadow
        />
        <spotLight
          position={[-20, -10, -10]}
          angle={0.35}
          penumbra={0.5}
          intensity={1.8}
          castShadow
        />
        <Suspense fallback={<LoderModel />}>
          <Model castShadow receiveShadow />
        </Suspense>
        <OrbitControls enablePan={true} enableZoom />
      </Canvas>
    </div>
  );
};

export default ModelView;
