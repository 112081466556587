import React from "react";
import { Html, useProgress } from "@react-three/drei";
import "./style.css";

const LoaderModel = () => {
  const { progress } = useProgress(); // Getting the loading progress
  
  return (
    <Html center>
      <div className="loader-container">
        <div className="loader">
          {/* Progress Bar Container */}
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${progress}%` }} // Dynamic width based on progress
            ></div>
          </div>
          {/* Progress Text */}
          <div className="progress-text">
            Loading... {Math.round(progress)}%
          </div>
        </div>
      </div>
    </Html>
  );
};

export default LoaderModel;
