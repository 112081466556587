import React from "react";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import Review from "../../../components/Review";
import { Helmet } from "react-helmet-async";

const VRCLass = () => {
  return (
    <div className="bk">
      <Helmet>
        <title>VRClass - Immersive 3D Models and VR Experiments</title>
        <meta
          name="description"
          content="VRClass specializes in developing immersive 3D models and virtual reality (VR) experiments for students in grades 6 to 12, enhancing their learning experience."
        />
        <meta
          name="keywords"
          content="VRClass, virtual reality, 3D models, immersive learning, education, EdTech"
        />
        <meta name="author" content="Niranjan Kumar" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://astomverse.com/vrclass" />
      </Helmet>
      <div className="flex flex-row align-middle justify-between">
        <div className="lg:w-10/12 mx-auto p-5 lg:p-10 my-10 mt-20 lg:my-28 flex flex-col-reverse lg:flex-row align-middle justify-between">
          <div className="lg:w-6/12">
            <div className="">
              <p className="text-white mb-5 mt-5 lg:mt-0 text-4xl lg:text-7xl lg:w-28 font-bold">
                VRClass
              </p>
              <p className="text-white lg:text-3xl mt-1 ">
                We specialize in developing immersive 3D models and virtual
                reality (VR) experiments to enhance the learning experience for
                students in classes 6th to 12th.
              </p>

              <div className="flex flex-col lg:flex-row gap-3 mt-5 justify-center align-middle ">
                <div className="flex justify-center align-middle p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                  <p className="text-white font-bold">
                    Accessible Educational Tools
                  </p>
                </div>
                <div className="flex justify-center align-middle p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                  <p className="text-white font-bold">
                    Engaging Interactive Content
                  </p>
                </div>
                <div className="flex justify-center align-middle p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                  <p className="text-white font-bold">
                    Consistent Learning Outcomes
                  </p>
                </div>
              </div>
              <p className="text-zinc-400 mx-4 mt-4  ">
                Our Mission is to revolutionize education by providing
                interactive and engaging VR solutions that make complex concepts
                easier to understand and retain.
              </p>
              <div className="mt-12 flex">
                <Link
                  to={"vrclass"}
                  className="lg:ml-3 w-full lg:w-4/12 flex justify-center hover:from-violet-600 hover:to-fuchsia-600  font-bold p-3 px-8 rounded-full text-white bg-gradient-to-r from-violet-500 to-fuchsia-500"
                >
                  Book a Demo
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-6/12 flex lg:justify-end">
            <video
              autoPlay
              loop
              muted
              className="lg:w-[260px ] lg:h-[580px]  outline-1 rounded-3xl outline-gray-700  outline"
            >
              <source
                src="https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/astomverse+video2.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      <div className=" flex flex-row align-middle justify-between">
        <div className="lg:w-10/12 mx-auto px-5 lg:gap-10  lg:p-10  lg:my-24 flex flex-col lg:flex-row align-middle justify-between">
          <div className="lg:w-8/12 flex justify-start">
            <img
              src="./vrclass.jpg"
              className=" lg:w-[380px ] lg:h-[460px]  outline-1 rounded-3xl outline-gray-700  outline"
            />
          </div>
          <div className="w-full lg:w-4/12 ">
            <div className="">
              <p className="text-white mb-5 mt-5 lg:mt-0 text-2xl lg:text-5xl font-bold">
                Immersive VR Learning Solution
              </p>
              <p className="text-white text-1xl lg:text-3xl lg:mt-1 ">
                Provides practical, hands-on learning experiences through
                immersive 3D models and virtual reality simulations.
              </p>

              <p className="text-zinc-400 text-sm lg:text-md mt-2 lg:mt-4  ">
                Students can conduct virtual experiments and observe scientific
                phenomena in a realistic lab environment. Visualizing molecular
                structures in 3D helps students understand their spatial
                relationships and properties.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" lg:w-10/12 gap-14 lg:gap-0 px-5 lg:px-0 mx-auto py-10 lg:py-28 flex flex-col lg:flex-row align-middle justify-between">
        <div className="mx-auto  lg:w-3/12 outline-1 rounded-3xl outline-gray-700  outline  ">
          <div className="">
            <img
              src="./5.png"
              className="lg:h-[330px] w-full outline-1 rounded-3xl outline-gray-700  outline"
            />
          </div>
          <div className="mt-2 p-3">
            <div className="">
              <p className="text-white text-2xl lg:text-3xl font-bold">
                Accessible Educational Tools
              </p>
              <p className="text-gray-300 text-1xl mt-1 ">
                Provides accessible learning tools suitable for schools with
                diverse resources, ensuring equitable access to high-quality
                education.Even schools without extensive lab equipment can offer
                comprehensive science education through Astomverse's virtual
                labs.
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto lg:w-3/12 outline-1 rounded-3xl outline-gray-700  outline flex flex-col align-middle">
          <div className="">
            <img
              src="./6.png"
              className="lg:h-[330px] w-full outline-1 rounded-3xl outline-gray-700  outline"
            />
          </div>
          <div className="mt-2 p-3">
            <div className="">
              <p className="text-white text-2xl lg:text-3xl font-bold">
                Engaging Interactive Content
              </p>
              <p className="text-gray-300 text-1xl mt-1 ">
                Captivates student interest with interactive simulations and
                experiences that go beyond static smart board presentations.
                Virtual labs and simulations make learning dynamic and
                enjoyable, fostering active participation.
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto lg:w-3/12 outline-1 rounded-3xl outline-gray-700  outline flex flex-col align-middle">
          <div className="">
            <img
              src="./7.png"
              className="lg:h-[330px] w-full outline-1 rounded-3xl outline-gray-700  outline"
            />
          </div>
          <div className="mt-2 p-3">
            <div className="">
              <p className="text-white text-2xl lg:text-3xl font-bold">
                Consistent Learning Outcomes
              </p>
              <p className="text-gray-300 text-1xl mt-1 ">
                Standardizes learning experiences with consistent, high-quality
                VR content, leading to improved academic performance across
                different schools. Example: Students achieve uniform
                understanding and competence in science concepts, regardless of
                geographical or resource disparities.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" flex flex-row align-middle justify-between">
        <div className="lg:w-10/12 px-5 lg:px-0 gap-5 lg:mx-auto  lg:my-16 flex flex-col lg:flex-row align-middle justify-between">
          <div className="lg:w-6/12 w-full flex align-middle justify-between">
            <div className="my-auto">
              <img
                src="./chem.png"
                className="lg:w-[460px ] lg:h-[400px]  outline-1 rounded-full outline-gray-700  outline"
              />
            </div>
            <div className=" flex flex-col gap-10">
              <img
                src="./phy.png"
                className="lg:w-[280px ] lg:h-[280px]  outline-1 rounded-full outline-gray-700  outline"
              />
              <img
                src="./bio.png"
                className="lg:w-[280px ] lg:h-[280px]  outline-1 rounded-full outline-gray-700  outline"
              />
            </div>
          </div>
          <div className="lg:w-6/12 w-full flex flex-row align-middle justify-end ">
            <div className="gap-8 w-full flex flex-col align-middle justify-center">
              <div className="mx-auto mt-5 lg:mt-0">
                <span className="text-white text-2xl lg:text-3xl">
                  Subscription Plan
                </span>
              </div>
              <div className="w-full mb-14 flex flex-col lg:flex-row gap-4">
                <div className="mx-auto lg:mt-5 outline-1 rounded-3xl p-8 lg:p-5 text-sm outline-gray-700  outline">
                  <div className="lg:mx-auto flex justify-center border-b border-b-zinc-700">
                    <span className="text-slate-200 mb-4 text-2xl ">
                      Grade 6th to 10th
                    </span>
                  </div>
                  <div className="mt-6">
                    <span className="text-slate-300  text-sm">
                      ✦ ₹1,000/month per headset
                    </span>
                  </div>
                  <div className="mt-5">
                    <span className="text-slate-300  text-sm">
                      ✦ ₹10,000/year per headset (Save ₹2,000)
                    </span>
                  </div>
                  <div className="mt-12 flex justify-center hover:from-violet-600 hover:to-fuchsia-600  font-bold p-3 px-8 rounded-full text-white bg-gradient-to-r from-violet-500 to-fuchsia-500">
                    <Link
                      to={"vrclass"}
                      className="w-full flex justify-center mx-auto"
                    >
                      Order
                    </Link>
                  </div>
                </div>
                <div className="mx-auto mt-5 outline-1 rounded-3xl p-8 lg:p-5 text-sm outline-gray-700  outline">
                  <div className="mx-auto flex justify-center border-b border-b-zinc-700">
                    <span className="text-slate-200 mb-4 text-2xl ">
                      Grade 11th & 12th
                    </span>
                  </div>
                  <div className="mt-6">
                    <span className="text-slate-300  text-sm">
                      ✦ ₹1,000/month per headset
                    </span>
                  </div>
                  <div className="mt-5">
                    <span className="text-slate-300  text-sm">
                      ✦ ₹10,000/year per headset (Save ₹2,000)
                    </span>
                  </div>
                  <div className="mt-12 flex justify-center hover:from-violet-600 hover:to-fuchsia-600  font-bold p-3 px-8 rounded-full text-white bg-gradient-to-r from-violet-500 to-fuchsia-500">
                    <Link
                      to={"vrclass"}
                      className="w-full flex justify-center mx-auto"
                    >
                      Order
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto lg:py-28 flex flex-col align-middle justify-between">
        <div className="mx-auto ">
          <span className="text-white text-2xl lg:text-2xl font-bold">
            Review of VRClass
          </span>
        </div>

        {/* <div className="mx-auto mt-10 flex flex-row gap-10">
          <Review />
          <Review />
          <Review />
        </div> */}
        <div className="gap-10 px-0 lg:mx-auto py-10 lg:py-28 flex flex-col lg:flex-row align-middle lg:justify-between">
          <div className="mx-auto outline-1  outline-gray-700  outline">
            <div className="">
              <YouTube
                videoId="trPXmqoaA78"
                opts={{ width: 300, height: 160 }}
              />
            </div>
          </div>
          <div className="mx-auto outline-1  outline-gray-700  outline">
            <div className="">
              <YouTube
                videoId="qYYkcJaKQyY"
                opts={{ width: 300, height: 160 }}
              />
            </div>
          </div>
          <div className="mx-auto outline-1  outline-gray-700  outline">
            <div className="">
              <YouTube
                videoId="bffARgpRDjc"
                opts={{ width: 300, height: 160 }}
              />
            </div>
          </div>
          <div className="mx-auto outline-1  outline-gray-700  outline">
            <div className="">
              <YouTube
                videoId="LuDLVP-tc34"
                opts={{ width: 300, height: 160 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VRCLass;
